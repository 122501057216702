import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/general.css"

const IndexPage = () => {

  const projects = [
        {
            name: "Zet-CLI",
            description: "A personalized memory management tool.",
            link: "https://github.com/mattdood/zet-cli"
        },
        {
            name: "Dockerized Web Scraping",
            description: "Scalable, customizable web scraping.",
            link: "https://github.com/mattdood/RSS_Scraping"
        },
        {
            name: "Mattdood.com",
            description: "A GatsbyJS project rendered with GitHub Actions from Markdown files.",
            link: "https://mattdood.com"
        },
        {
            name: "brandobot",
            description: "Toy Discord bot that tells you info about the weather, Reddit, and Twitter.",
            link: "https://github.com/mattdood/brandobot"
        },
  ]
  return (
    <Layout>
      <Seo title="Home" />
      <h1>Matthew Wimberly</h1>
      <p>
        This site is autogenerated and updates periodically with new
        blog posts.My posts are from a
        personal <a href="https://en.wikipedia.org/wiki/Zettelkasten" target="_blank" rel="noopener noreferrer">"Zettlekasten"</a> repository.
        These are created with my <a href="https://github.com/mattdood/zet-cli/" target="_blank" rel="noopener noreferrer"> Zet-CLI </a> tool.
      </p>
      <h2>About me</h2>
      <div className={"row"}>
        <div className={"column"}>
          <StaticImage
            src="../images/family-drawing.png"
            width={300}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A digital render of me, my partner, and our dog."
            style={{ marginBottom: `1.45rem` }}
          />
        </div>
        <div className={"column"}>
          <p>
            I work within a few different aspects of development. My
            main focus is backend work; however, I have some significant
            experience in performance tuning of large database systems.
          </p>
          <a
            href="https://www.linkedin.com/in/matthew-wimberly/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              marginLeft: "2.5%"
            }}
          >
            <i className={"fab fa-linkedin fa-3x"}></i>
          </a>
          <a
            href="https://github.com/mattdood"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              marginLeft: "2.5%"
            }}
          >
            <i className={"fab fa-github-square fa-3x"}></i>
          </a>
        </div>
      </div>
      <br/>
      <br/>
      <h3>Project work</h3>
      <div>
        {projects
          .map((project) => {
            return (
              <div>
                <h4 key={project}>
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  {project.name}
                  </a>
                </h4>
                <h5>
                  {project.description}
                </h5>
              </div>
            )
          })
        }
      </div>
    </Layout>
  )
}

export default IndexPage
